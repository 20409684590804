import Experiments from '@wix/wix-experiments';

let conductedExperiments: Experiments;

async function maybeConductExperiments() {
  if (!conductedExperiments) {
    conductedExperiments = new Experiments({ scope: 'members-area' });
  }
  await conductedExperiments.ready();
}

async function areAppWidgetsEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AppWidgets') === 'true';
}

async function isADIHorizontalLayoutEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.ADIHorizontalLayout') === 'true';
}

async function shouldDisableBrokenMADeletion() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.disableAutoRemoval') === 'true';
}

async function isAddMinHeightEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.AddMinHeight') === 'true';
}

async function isAddUninstallMAActionEnabled() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.AddUninstallMAAction') === 'true';
}

async function shouldSetResponsiveLayoutForApps() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.setResponsiveLayoutForApps') === 'true';
}

async function shouldRevertOnInstallationErrors() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.RevertOnInstallationErrors') === 'true';
}

async function shouldUsePlatformisedPermissionsPanel() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.UsePlatformisedPermissionsPanel') === 'true';
}

async function shouldFixCorruptedRouterPatterns() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.FixCorruptedRouterPatterns') === 'true';
}

async function shouldUseAppWidgetsRelayout() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.UseAppWidgetsRelayout') === 'true';
}

async function shouldListenToComponentAddedToStageEvent() {
  await maybeConductExperiments();
  return conductedExperiments.get('specs.membersArea.EnableComponentAddedToStage') === 'true';
}

export {
  areAppWidgetsEnabled,
  maybeConductExperiments,
  isADIHorizontalLayoutEnabled,
  shouldDisableBrokenMADeletion,
  isAddMinHeightEnabled,
  isAddUninstallMAActionEnabled,
  shouldSetResponsiveLayoutForApps,
  shouldRevertOnInstallationErrors,
  shouldUsePlatformisedPermissionsPanel,
  shouldFixCorruptedRouterPatterns,
  shouldUseAppWidgetsRelayout,
  shouldListenToComponentAddedToStageEvent,
};
